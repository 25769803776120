/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Open+Sans&display=swap');

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  background-color: #f4f7fb;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}

a:hover {
  color: #005f8e;
}

li {
  list-style: none;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10%;
  background-color: #ffffff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 10;
}

.logo {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #0077b6;
}

.nav-links {
  display: flex;
  gap: 30px;
}

.nav-links li a {
  font-weight: 600;
  transition: color 0.3s;
}

/* Hero Section Styles */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 10%;
  background: linear-gradient(135deg, #0077b6 30%, #023e8a);
  color: #ffffff;
}

.hero-content {
  max-width: 600px;
}

.hero-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.25rem;
  margin-bottom: 40px;
  line-height: 1.7;
}

.cta-button {
  padding: 18px 35px;
  font-size: 1.1rem;
  font-weight: 700;
  color: #0077b6;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: #d9e9f5;
  transform: translateY(-2px);
}

.hero-image {
  max-width: 500px;
}

.hero-image img {
  width: 100%;
  height: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

/* Main Content Styles */
.main-content {
  padding: 80px 10%;
  background-color: #ffffff;
}

.input-section {
  max-width: 900px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.input-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  color: #0077b6;
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.form-group textarea {
  width: 100%;
  height: 150px;
  padding: 15px;
  font-size: 1rem;
  border: 2px solid #d1e3f0;
  border-radius: 10px;
  resize: vertical;
  transition: border-color 0.3s;
}

.form-group textarea:focus {
  border-color: #0077b6;
  outline: none;
}

.generate-button {
  width: 100%;
  padding: 18px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #0077b6;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.generate-button:hover {
  background-color: #005f8e;
  transform: translateY(-3px);
}

.generate-button:disabled {
  background-color: #a1cbe1;
  cursor: not-allowed;
}

/* Loader Styles */
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #0077b6;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Footer Styles */
.footer {
  text-align: center;
  padding: 30px 10%;
  background-color: #f4f7fb;
  color: #666;
  font-size: 1rem;
  border-top: 1px solid #e0e0e0;
}

.footer p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    padding: 60px 5%;
  }

  .hero-content {
    margin-bottom: 40px;
  }

  .hero-image {
    max-width: 100%;
  }

  .input-section {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .cta-button {
    padding: 15px 25px;
    font-size: 1rem;
  }

  .generate-button {
    font-size: 1.1rem;
  }
}