.slide-preview {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.slide-content {
  text-align: center;
}

.slide-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: bold;
}

.slide-subtitle {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #6c757d;
}

.slide-bullets {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}

.slide-bullet {
  margin-bottom: 5px;
}
