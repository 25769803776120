.slide-bullets-editor {
    margin-bottom: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .slide-bullets-editor .DraftEditor-root {
    font-size: 1.2em;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  