.slide-editor {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f1f3f4;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.slide-editor-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 70px;
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Slide Container */
.slide-container {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
}

.sidebar {
  width: 15%;
  height: calc(100% - 70px);
  overflow-y: auto;
  margin-right: 20px;
  border-right: 1px solid #ccc;
  padding-right: 10px;
  background-color: #fff;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
}

.slide-thumbnail {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  aspect-ratio: 1 / 1;
}

.slide-thumbnail.active {
  background-color: #e0e0e0;
  border-color: #007bff;
}

.main-slide-editor {
  width: 60%;
  margin-left: 15%;
  height: calc(100% - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.overall-prompt-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 20%;
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}

.textarea-prompt {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

button {
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}

