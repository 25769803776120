.slide-container-outer {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #ccc; /* Elegant rectangle around the slide */
}

.slide-container {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.slide-title-editor {
  margin-bottom: 10px;
}

.slide-subtitle-editor {
  margin-bottom: 10px;
}

.slide-bullets-editor {
  margin-bottom: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

h1, h2 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
  color: #6c757d;
}

ul {
  padding-left: 20px;
  list-style-type: disc;
}

ul li {
  font-size: 1.2em;
  margin-bottom: 5px;
}
