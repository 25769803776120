.right-sidebar {
    width: 20%;
    height: calc(100% - 70px);
    overflow-y: auto;
    padding: 20px;
    background-color: #fff;
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    border-left: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .textarea-prompt {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
  }
  
  button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  